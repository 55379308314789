.booking-limit-card {
	padding: 1rem;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-message {
	color: #e74c3c;
	/* Red color for error messages */
	font-weight: bold;
}

.help-message {
	margin-top: 10px;
	color: #333;
	/* Regular color for additional information */
}